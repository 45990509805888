import styles from "./styles/Home.module.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Box, Drawer, IconButton } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import MenuIcon from "@mui/icons-material/Menu";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ArrowForward } from "@mui/icons-material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EF5B25",
    },
  },
});

function smoothScroll(targetId: string): void {
  const targetElement: HTMLElement | null = document.getElementById(targetId);

  if (!targetElement) {
    return;
  }

  const targetPosition: number = targetElement.offsetTop - 140;
  const startPosition: number = window.pageYOffset;
  const distance: number = targetPosition - startPosition;
  const duration: number = 1000; // in milliseconds
  let startTime: number | undefined;

  function animation(currentTime: number): void {
    if (startTime === undefined) {
      startTime = currentTime;
    }

    const timeElapsed: number = currentTime - startTime;
    const progress: number = Math.min(timeElapsed / duration, 1);
    window.scrollTo(0, startPosition + distance * easeInOutCubic(progress));

    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    }
  }

  function easeInOutCubic(t: number): number {
    return t < 0.5 ? 4 * t ** 3 : 1 - Math.pow(-2 * t + 2, 3) / 2;
  }

  requestAnimationFrame(animation);
}

export default function Home() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const headerRef = useRef<HTMLElement | null>(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Remova o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 100;
      const scrollPosition = window.scrollY;

      if (headerRef.current) {
        if (scrollPosition > scrollThreshold) {
          headerRef.current.style.backgroundColor = "#000954";
        } else {
          headerRef.current.style.backgroundColor = "transparent";
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const RenderHeroSectionContent = () => {};

  function openWhatsApp() {
    // Número de telefone para o qual você deseja abrir o WhatsApp (substitua com o número desejado)
    var phoneNumber = "+5527997161387";

    var defaultMessage = "Olá! Estou entrando em contato para fazer um orçamento.";

    var encodedMessage = encodeURIComponent(defaultMessage);

    // Construa o URL do WhatsApp
    var whatsappUrl = "https://wa.me/" + phoneNumber + "?text=" + encodedMessage;

    // Abra o WhatsApp em uma nova janela ou guia
    window.open(whatsappUrl, "_blank");
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <header className={styles.header} ref={headerRef}>
          <div className={styles.navBar}>
            <img src="Logo.svg" className={styles.logoSvg} alt="Mudanças Klein" />

            <div className={styles.navAncors}>
              <button onClick={() => smoothScroll("home")}>Home</button>
              <button onClick={() => smoothScroll("services")}>Serviços</button>
              <button onClick={() => smoothScroll("about-us")}>Sobre Nós</button>
              <button onClick={() => smoothScroll("contacts")}>Contatos</button>
            </div>

            <div className={styles.hamburger}>
              <IconButton size="large" edge="end" color="primary" onClick={() => setIsMenuOpen(true)}>
                <MenuIcon></MenuIcon>
              </IconButton>
              <Drawer
                anchor="right"
                open={isMenuOpen}
                onSelect={() => setIsMenuOpen(false)}
                onClose={() => setIsMenuOpen(false)}
              >
                <Box
                  bgcolor={"#000954"}
                  height="100vh"
                  p={2}
                  width="250px"
                  textAlign="center"
                  role="presentation"
                  display="flex"
                  flexDirection="column"
                >
                  <button className={styles.boxAncor} onClick={() => smoothScroll("home")}>
                    Home
                  </button>
                  <button className={styles.boxAncor} onClick={() => smoothScroll("services")}>
                    Serviços
                  </button>
                  <button className={styles.boxAncor} onClick={() => smoothScroll("about-us")}>
                    Sobre Nós
                  </button>
                  <button className={styles.boxAncor} onClick={() => smoothScroll("contacts")}>
                    Contatos
                  </button>
                </Box>
              </Drawer>
            </div>
          </div>
        </header>

        <section className={styles.heroSection} id="home" onClick={() => smoothScroll("home")}>
          <h1>
            Mudanças <span>Klein</span>
          </h1>
          <h3>
            Há <span>20 anos</span> transportando <br />
            com confiança
          </h3>

          <button className={styles.budgetButton} onClick={() => openWhatsApp()}>
            <div className={styles.budgetButtonDiv}>
              <p>Faça seu orçamento</p>
              <ArrowForward></ArrowForward>
            </div>
          </button>
        </section>

        <section className={styles.ourServices} id="services" onClick={() => smoothScroll("services")}>
          <h1 className={styles.ourServicesTitle}>Nossos Serviços</h1>

          <div className={styles.serviceCardsContainer}>
            <div className={styles.blueCard}>
              <img src="house-icon.svg" alt="Mudança Residencial" />
              <h1>Mudança Residencial</h1>
              <div>
                <p>
                  Estamos prontos para ajudá-lo em cada etapa do processo, oferecemos a segurança dos seus pertences com
                  os nossos serviços personalizados que atendem às suas necessidades.
                </p>
              </div>
            </div>

            <div className={styles.orangeCard}>
              <img src="comercial-icon.svg" alt="Mudança Residencial" />
              <h1>Mudança Comercial</h1>
              <div>
                <p>
                  Atendemos às suas necessidades específicas de forma personalizada, proporcionando uma transição suave
                  para o seu novo local de negócios.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.serviceCardsContainer}>
            <div className={windowSize.width <= 768 ? styles.blueCard : styles.orangeCard}>
              <img
                style={{ height: "90px" }}
                src={windowSize.width <= 768 ? "bubble-wrap.svg" : "bubble-wrap-orange.svg"}
                alt="Embalagem"
              />
              <h1>Embalagem</h1>
              <div>
                <p>
                  Facilite sua mudança! Oferecemos serviços especializados de embalagem de móveis para garantir proteção
                  e segurança. Conte conosco para uma mudança tranquila e preservação dos seus pertences.
                </p>
              </div>
            </div>
            <div className={windowSize.width <= 768 ? styles.orangeCard : styles.blueCard}>
              <img
                src={windowSize.width <= 768 ? "assemble-icon-orange.svg" : "assemble-icon.svg"}
                alt="Montagem e Desmontagem"
              />
              <h1>Montagem e Desmontagem</h1>
              <div>
                <p>
                  Oferecemos serviços especializados em montagem e desmontagem de móveis, garantindo comodidade e
                  tranquilidade. Além disso, contamos com ajudantes experientes para tornar diversas tarefas mais
                  fáceis, proporcionando assistência confiável e comprometida.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <section className={styles.roadmapContainer} id="about-us">
          <div className={styles.roadmapImg} />
        </section> */}

        <section className={styles.videoSection}>
          <div className={styles.videoContainer}>
            <div>
              <h1>Um pouco do nosso trabalho</h1>
              <p>
                Prontos para ajudá-lo em cada etapa do processo. Nossa equipe é treinada e experiente para garantir a
                segurança dos seus pertences. Oferecemos serviços personalizados para atender às suas necessidades
                individuais. Estamos sempre disponíveis para responder a quaisquer perguntas que você possa ter. Entre
                em contato conosco hoje mesmo para saber mais sobre nossos serviços.
              </p>
            </div>
            <video className={styles.video} controls width="30%" muted autoPlay>
              <source src="video-carregamento.mp4" type="video/mp4" />
            </video>
          </div>
        </section>

        <h1 className={styles.ourHistoryTitle} id="about-us" onClick={() => smoothScroll("about-us")}>
          Nossa história
        </h1>

        <div className={styles.aboutUs}>
          <img className={styles.familyPicture} src="foto-familia.jpg" />
          <p>
            Em 2004, a empresa de mudança e transporte começou sua jornada sob a liderança do Sr. Gilmar Klein, que
            inicialmente operava praticamente sozinho, atendendo à demanda do mercado de mudanças. Com uma abordagem
            pessoal e compromisso com a excelência no atendimento ao cliente, conquistou a confiança de muitos. Ao longo
            dos anos, a empresa cresceu e seus filhos, Otávio e Ricardo, que compartilhavam seus princípios, passaram a
            também atuar na empresa. Com 20 anos de experiência, a empresa é agora reconhecida como uma das mais
            confiáveis e respeitadas em sua região, continuando a crescer e a servir uma base de clientes fiel. A
            família Klein mantém sua paixão por tornar as mudanças uma experiência sem estresse.
          </p>
        </div>

        <section className={styles.customersFeedbacksContainer}>
          <div className={styles.customersFeedbacks}>
            <h1>
              Veja o que os nossos <br />
              clientes falam!
            </h1>
            <div className={styles.carrouselContainer}>
              <Carousel
                showArrows={false}
                emulateTouch={true}
                showStatus={false}
                showThumbs={false}
                centerMode={true}
                selectedItem={0} // Iniciar na segunda imagem para exibir três imagens por vez
                useKeyboardArrows={true}
                dynamicHeight={false}
                showIndicators={false}
              >
                <div className={styles.customerCard}>
                  <div className={styles.cardHeader}>
                    {/* <div className={styles.cardPicture}>
                      <img src="Logo.svg" />
                    </div> */}
                    <div className={styles.cardHeaderText}>
                      <h2 className={styles.cardPersonName}>Ana Carolina </h2>
                      <h2 className={styles.cardCityName}>Domingos Martins - ES</h2>
                    </div>
                  </div>
                  <p>
                    Contratei os serviços da equipe do Otávio para minha mudança e foi impecável! Rápidos, cuidadosos e
                    super profissionais. Recomendo!
                  </p>
                </div>

                <div className={styles.customerCard}>
                  <div className={styles.cardHeader}>
                    {/* <div className={styles.cardPicture}>
                      <img src="Logo.svg" />
                    </div> */}
                    <div className={styles.cardHeaderText}>
                      <h2 className={styles.cardPersonName}>Eduardo Simon</h2>
                      <h2 className={styles.cardCityName}>Vila Velha - ES</h2>
                    </div>
                  </div>
                  <p>
                    Escolhi eles para fazer a mudança da minha casa, e foi rápido e eficiente. Cada membro da equipe era
                    bem atencioso e cuidou bem das minhas coisas.
                  </p>
                </div>

                <div className={styles.customerCard}>
                  <div className={styles.cardHeader}>
                    {/* <div className={styles.cardPicture}>
                      <img src="Logo.svg" />
                    </div> */}
                    <div className={styles.cardHeaderText}>
                      <h2 className={styles.cardPersonName}>Marta Santos</h2>
                      <h2 className={styles.cardCityName}>Vitória - ES</h2>
                    </div>
                  </div>
                  <p>
                    É legal ver a galera sendo fiel à família Klein. A confiança que temos neles ao longo dos anos é
                    porque o serviço é sempre de qualidade.
                  </p>
                </div>
              </Carousel>
            </div>
          </div>
        </section>

        <section className={styles.interestContainer} id="contacts" onClick={() => smoothScroll("contacts")}>
          <h1>
            Se interessou pelos <br />
            nossos serviços?
          </h1>
          <div className={styles.interestCard}>
            <div className={styles.interestCardOrangeLine}></div>
            <h1>
              Entre em contato para <br />
              marcar a sua mudança!
            </h1>
            <button onClick={openWhatsApp}>Agendar mudança</button>
          </div>
        </section>

        <section className={styles.footer}>
          <div className={styles.footerInfoContainer}>
            <div className={styles.cardPicture}>
              <img alt="Logo Mudanças Klein" src="Logo.svg" />
            </div>
            <div className={styles.cardHeaderText}>
              <p>montadordemoveisdm@gmail.com</p>
              <p>(27) 99716-1387</p>
            </div>
          </div>
          <div className={styles.footerLinksContainer}>
            <div className={styles.footerLinksLeftContainer}>
              <a href="#services">Serviços</a>
              <a href="#contacts">Agendamento</a>
              <a href="#about-us">Sobre nós</a>
            </div>
            <div className={styles.footerLinksRightContainer}>
              <a href="https://www.instagram.com/mudancasklein/" target="_blank" rel="noreferrer">
                Instagram
              </a>
              <a href="https://www.facebook.com/Mudancasklein" target="_blank" rel="noreferrer">
                Facebook
              </a>
              <a target="_blank" onClick={openWhatsApp}>
                WhatsApp
              </a>
            </div>
          </div>
          <span>
            Copyright © 2023 Mudanças Klein. <br /> Todos os direitos reservados
          </span>
        </section>
      </ThemeProvider>
    </>
  );
}
